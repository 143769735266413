import React from 'react';
import classes from "./LogoToAllPostsOfOneUser.module.css";
import socialLogo from "./assets2/images/picture_social_media_2.png";


const LogoToAllPostsOfOneUser = () => {
  return (
    <div className={classes.logoToAllPostsOfOneUser}>
       <img crossOrigin="anonymous" 
        src={ socialLogo }  alt="logo"/>
    </div>
  )
}

export default LogoToAllPostsOfOneUser;
