import { useEffect, useRef, useState} from "react";
import Button from "../UI/Button";
import classes from "./AuthForm.module.css";
import ErrorModal from "../UI/ErrorModal";
import Wrapper from "../Helpers/Wrapper";
import Loader from "../UI/Loader";
import {useDispatch, useSelector} from "react-redux";
import { postFetchLoginAuthentification } from "../../store/actions/authentification-action";
import { authentificationActions } from "../../store/slices/authentification-slice";
import CookieConsent from "react-cookie-consent";

const AuthForm = () => {
     
     
    const dispatch = useDispatch();

     const emailInputRef = useRef();
     const passwordInputRef = useRef();
     const passwordControleInputRef = useRef();

     const [isLogin, setIsLogin] = useState(true);
     const [passwordPlain, setPasswordPlain] = useState(false);
     const [error, setError] = useState(null);
     

   const errorFetch = useSelector((state) => state.authentification.errorFetch);
    const accountCreate = useSelector((state) => state.authentification.accountCreate);


     //Le spinner - ou loader
    const isLoading = useSelector((state) => state.authentification.isLoading);
     

     
     const toggleAuthModeHandler = () => {
        setIsLogin((prevState) => !prevState);    
        dispatch(authentificationActions.accountCreate(false));
     };

     const errorHandler = () => {
        setError(null);
        dispatch(authentificationActions.resetErrorFetch());
     };

     //lire dans le local Storage si un token est présent lorsque l'utilisateur actualise l'application
     //et ainsi ne perdre l'interface en mode connexion
     useEffect(() => {
           dispatch(authentificationActions.localStorageAuth());
     }, [dispatch]);


    const submitHandler = (event) => {
           event.preventDefault();

           const enteredEmail = emailInputRef.current.value;
           const enteredPassword = passwordInputRef.current.value;


           let enteredPasswordControl;
           if(isLogin) {
              enteredPasswordControl = enteredPassword;
          }else {
              enteredPasswordControl = passwordControleInputRef.current.value;
           };


           //contrôle input pas vide
           if(
                enteredEmail.trim().length === 0 ||
                enteredPassword.trim().length === 0 
                ) {
                 setError({
                     
                       title: "Un ou plusieurs champs sont vides",
                       message: "Entrez votre email et/ou votre mot de passe",
                    
                  });

                  return;
           };


           //contrôle validité email
           const regExEmail = (value) => {
                return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value);
           };


          if(!regExEmail(enteredEmail)) {
              
             setError({
              
                 title: "Email invalide",
                 message: "Entrez un format valide",
              
            });
            
             return;
          };


           //contrôler que le password est identique dans les deux champs password
           const samePassword = enteredPassword === enteredPasswordControl;
           if(!samePassword) {
            
             setError({
             
               title: "Mots de passe différents",
               message: "Les mots de passe doivent être identiques dans les deux champs",
            
          });
              
        
             return;
        };



        
        //------------------------------//________________________________//
          //Créer également un programme pour l'utilisateur qui aurait oublié son mot de passe//
       


          const credential = {
                email: enteredEmail,
                password: enteredPassword,
                
          };

          //Affichage du spinner
          dispatch(authentificationActions.isLoading(true));
         
          //envoie de la requête login et création de compte
          dispatch(postFetchLoginAuthentification(isLogin, credential));
         
         };


         //gérer les modales d'erreur
         useEffect(() => {

          //Echec de connexion - Authentification échec
          if(isLogin && errorFetch) {
            setError({
              title: "Echec d'authentification",
              message: errorFetch.error,
            })
          }

          //gérer l'erreur lors de la création de compte 
          //- email déjà existant - password trop faible
          if(!isLogin && errorFetch) {
            setError({
              title: "Il y a un problème",
              message: errorFetch.error.code || errorFetch.error,
            })
          }

          //basculer sur l'interface de connexion lorsque le compte vient d'être créé
          if(!isLogin && !errorFetch && accountCreate) {
            setIsLogin(true);
          }

         }, [isLogin, errorFetch, accountCreate]);



          //Gérer l'affichage en clair du password
          const passwordPlainHandler = () => {
              setPasswordPlain((prevState) => !prevState);
          }
     


    return ( 
<> 
    <Wrapper className={classes.wrapper}>
      
       {
         error &&
          <ErrorModal
            title={error.title} 
            message={error.message}
            onConfirm={errorHandler}
         />

       }
      
      <section className={classes.auth}> 
       
        { isLogin ?
          <h1>Se connecter</h1>
           :
          <h1>Créer un compte</h1>
         }
    
        <form onSubmit={submitHandler}>
           
           <div className={classes.control}>  
                <label 
                 className={classes.emailLabel}
                 htmlFor="email">Email</label>
                <input type="email" ref={emailInputRef} id="email"
                placeholder="Email"
                autocomplete="on"  
                     />
           </div>


           <div className={classes.control}>
              <label 
                  className={classes.passwordLabel}
                   htmlFor="password">
                    Mot de passe
                </label>
                <input
                   type={passwordPlain ? "text" : "password"}  
                   ref={passwordInputRef} 
                   id="password" 
                   placeholder="Mot de passe"
                   autocomplete="on"
                   />

                
                 { !isLogin && (  
                  <> 
                    <label 
                      className={classes.confirmationPasswordLabel}
                      htmlFor="password">
                        Confirmer le mot de passe
                    </label>
                    <input 
                      className={classes.confirmationPasswordInput}
                      type={passwordPlain ? "text" : "password"} 
                      ref={passwordControleInputRef} 
                      id="passwordControl"
                      placeholder="Confirmer le mot de passe"
                      autocomplete="on" 
                        />
                  </>
                  )
                    }



                    {/**Pour afficher en clair le mot de passe */}
                    <div className={classes.checkbox}>
                      <label 
                          htmlFor="displayPassword">
                            Afficher le mot de passe
                      </label>
                      <input
                          type="checkbox"
                          id="displayPassword"
                          checked={passwordPlain}
                          onChange={passwordPlainHandler}
                           />
                    </div>


                   { !isLogin && (
                    <ul>
                      <li><p>Votre mot de passe doit contenir au moins</p></li>
                      <li><p>5 caractères, dont</p></li>
                      <li><p>2 majuscules</p></li>
                      <li><p>et 2 chiffres</p></li>
                    </ul>
                    )
                  }

                
                

           </div>

           <div className={classes.actions}>
               
                { !isLoading &&
                 <Button 
                   type={"submit"}
                   >
                     {isLogin ? "Se connecter" : "Créer un compte"}
                 </Button>
                   }


                      <p onClick={toggleAuthModeHandler}>
                         {isLogin ? "Créer un compte" : "Se connecter"}
                      </p>

                 {/***isLoading: en cours de chargement***/}
                 { isLoading &&
                   <Loader />
                 }

           </div>

        </form>
      </section>
    </Wrapper> 
        


        <div>
        <CookieConsent
        location="bottom"
        buttonText="J'accepte"
        declineButtonText="Je refuse" 
        declineButtonStyle={{color: "#800080", fontSize: "13px" , background: "#fff"}}
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ background: "#800080", color: "#fff", fontSize: "13px" }}
        expires={150}
        enableDeclineButton	
        overlay
      >
       Ce site Web utilise des cookies pour améliorer l'expérience utilisateur.{" "}
       
    </CookieConsent>
        </div>

        </>
        )
       
    
}


export default AuthForm;
