import React, { useCallback, useEffect, useState } from 'react';
import classes from "./FeedCommentPhotoUser.module.css";
import emptyPortrait from "../../../assets/images/photo-avatar.png";



const FeedCommentPhotoUser = ({token, userIdToken, userIdComment}) => {

 const [ficheUser, setFicheUser] = useState(null);

  

  const fetchGetFicheUserHandler = useCallback(async () => {
    
    //récupérer dans la base de données la photo de l'utilisateur qui vient d'effectuer un commentaire
     const url = `${process.env.REACT_APP_GRAFFITI_API_URL}/api/fiche_user/fiche/${userIdComment}?userId=${userIdToken}`;
     
     try {

          const response = await fetch(url, {
                  method: "GET",
                  headers: {
                     Authorization: `Bearer ${token}`,
                  },
               }
          );

          const dataResponse = await response.json();

            if(response.ok) {
               console.log(dataResponse);
               setFicheUser(dataResponse.results);
            } else {
              console.log(dataResponse);
              throw new Error(dataResponse.error);
            }



      } catch(error) {
         console.log(error);
      }
   
    }, [token, userIdComment, userIdToken]);


   useEffect(() => {
       fetchGetFicheUserHandler();
   }, [fetchGetFicheUserHandler]);



  return (

    <> 
      { 
       ficheUser && ( 
        <div className={classes.feedCommentPhotoUser}>
          <img 
             crossOrigin="anonymous"
             src={
                  ficheUser[0].fiche_user_photoProfilUrl ? 
                  ficheUser[0].fiche_user_photoProfilUrl : 
                  emptyPortrait
                } 
             alt="user"
             />
        </div>

      )}
    </>
  )
}

export default FeedCommentPhotoUser;
